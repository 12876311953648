import { Button, ButtonGroup, Grid, Paper, Typography } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import React from "react";
import { useState } from "react";
import { Await, useLoaderData } from "react-router";
import { Link } from "react-router-dom";
import Papa from "papaparse";
import { manualCCOFSAniversaryTrigger } from "./api/ku-ordering-api/ShopifyCCOFSAnniversarySync";

export default function ShopifyDiscountCodes() {
  const [price_rules, orders, customers] = useLoaderData();

  const [selectedDiscount, setSelectedDiscount] = useState("");
  const [filteredOrders, setFilteredOrders] = useState([]);
  const [filteredCustomers, setFilteredCustomers] = useState([]);
  const [selectedOrderTotal, setSelectedOrderTotal] = useState();
  const [selectedOrders, setSelectedOrders] = useState();

  const columns_orders = [
    {
      field: "fullName",
      headerName: "Full name",
      sortable: false,
      width: 160,
      valueGetter: (params) =>
        `${params.row.customer.first_name || ""} ${
          params.row.customer.last_name || ""
        }`,
    },
    {
      field: "created_at",
      headerName: "Created At",
      sortable: false,
      width: 160,
      valueGetter: (params) => new Date(params.row.created_at).toLocaleString(),
    },
    {
      field: "total_price",
      headerName: "Subtotal",
      width: 100,
      valueGetter: (params) => `$${params.row.total_price}`,
    },
    {
      field: "active_discount_code_amt",
      headerName: `$ On ${selectedDiscount}`,
      sortable: false,
      width: 160,
      valueGetter: (params) =>
        `$${params.row.discount_codes
          .filter((discount) => discount.code === selectedDiscount)
          .map((discount) => discount.amount)
          .reduce((val1, val2) => parseFloat(val1) + parseFloat(val2))}`,
    },
    {
      field: "internal_link_1",
      headerName: "Customer",
      width: 100,
      renderCell: (params) => {
        return (
          <Link to={"/shopify-customers/" + params.row.customer.id}>Link</Link>
        );
      },
    },
    {
      field: "internal_link_2",
      headerName: "Order",
      width: 100,
      renderCell: (params) => {
        return <Link to={"/shopify-orders/" + params.id}>Link</Link>;
      },
    },
  ];

  const columns_customers = [
    {
      field: "fullName",
      headerName: "Full name",
      sortable: false,
      width: 160,
      valueGetter: (params) =>
        `${params.row.first_name || ""} ${params.row.last_name || ""}`,
    },
    {
      field: "workLocation",
      headerName: "Work Location",
      sortable: false,
      width: 160,
      valueGetter: (params) => {
        let work_location = params.row.meta.find(
          (meta) => meta.key === "work_location"
        );
        return work_location ? work_location.value : null;
      },
    },
    {
      field: "employer",
      headerName: "Employer",
      sortable: false,
      width: 160,
      valueGetter: (params) => {
        let work_location = params.row.meta.find(
          (meta) => meta.key === "employer"
        );
        return work_location ? work_location.value : null;
      },
    },
    {
      field: "link",
      headerName: "Customer Link",
      sortable: false,
      width: 160,
      renderCell: (params) => {
        return <Link to={"/shopify-customers/" + params.row.id}>Link</Link>;
      },
    },
    {
      field: "Links To Orders",
      headerName: "Order Links",
      sortable: false,
      width: 160,
      renderCell: (params) => {
        let orders_with_code = orders.filter(
          (order) =>
            order.customer.id === params.row.id &&
            order.discount_codes.find((code) => code.code === selectedDiscount)
        );
        return orders_with_code.length === 0 ? (
          <div>Has Not Used</div>
        ) : (
          orders_with_code.map((order) => (
            <React.Fragment key={order.id}>
              <Link to={"/shopify-orders/" + order.id}>Link</Link>
              &nbsp;
            </React.Fragment>
          ))
        );
      },
    },
  ];

  let allPriceRuleTitles = [
    ...new Set(
      price_rules.map((price_rule) => {
        return price_rule.title;
      })
    ),
  ];

  function codeBtnOnClick(event) {
    let activeDiscount = event.target.value;

    setSelectedDiscount(activeDiscount);

    let activeOrders = orders.filter(
      (order) =>
        order.discount_codes.filter(
          (discount) => discount.code === activeDiscount
        ).length > 0
    );

    let assignedCustomers = price_rules
      .find((pr) => pr.title === activeDiscount)
      .prerequisite_customer_ids.map((cust_id) => {
        return customers.find((cust) => cust.id === cust_id);
      });

    setFilteredOrders(activeOrders);
    setFilteredCustomers(assignedCustomers);
  }

  function onOrderRowSelect(selection) {
    setSelectedOrders(selection);

    const selectedRowData = selection.map((order_id) =>
      filteredOrders.find((order) => order.id === order_id)
    );

    const row_total = selectedRowData
      .map((row) =>
        row.discount_codes
          .filter((discount) => discount.code === selectedDiscount)
          .map((discount) => discount.amount)
          .reduce((val1, val2) => parseFloat(val1) + parseFloat(val2), 0)
      )
      .reduce((val1, val2) => parseFloat(val1) + parseFloat(val2), 0);

    setSelectedOrderTotal(row_total);
  }

  function handleExportBtn(event) {
    let selectedRowData = selectedOrders.map((order_id) =>
      filteredOrders.find((order) => order.id === order_id)
    );

    selectedRowData = selectedRowData.map((row) => {
      return {
        order_id: row.id,
        first_name: row.customer.first_name,
        last_name: row.customer.last_name,
        email: row.customer.email,
        customer_id: row.customer.id,
        customer_tags: row.customer.tags,
        created_at: row.created_at,
        tags: row.tags,
        subtotal: row.subtotal_price,
        total_price: row.total_price,
        total_tax: row.Total_tax,
        discount_used: row.discount_codes
          .filter((discount) => discount.code === selectedDiscount)
          .map((discount) => discount.amount)
          .reduce((val1, val2) => parseFloat(val1) + parseFloat(val2)),
      };
    });
    const csv = Papa.unparse(selectedRowData);

    const today = new Date().toJSON().slice(0, 10);
    const fileName = `${today}-${selectedDiscount}.csv`;

    const csvData = new Blob([csv], { type: "text/csv;charset=utf-8;" });
    let csvURL = null;
    if (navigator.msSaveBlob) {
      csvURL = navigator.msSaveBlob(csvData, fileName);
    } else {
      csvURL = window.URL.createObjectURL(csvData);
    }
    const tempLink = document.createElement("a");
    tempLink.href = csvURL;
    tempLink.setAttribute("download", fileName);
    tempLink.click();
  }

  function ccofsSyncBtn(event) {
    manualCCOFSAniversaryTrigger();
  }

  return (
    <Await resolve={orders}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Paper
            sx={{
              p: 2,
              display: "flex",
              flexDirection: "column",
              height: "auto",
            }}
          >
            <Typography variant="h6">Discount Codes Used</Typography>
            <ButtonGroup
              variant="text"
              aria-label="outlined primary button group"
            >
              {allPriceRuleTitles.map((code) => (
                <Button key={code} value={code} onClick={codeBtnOnClick}>
                  {code}
                </Button>
              ))}
            </ButtonGroup>
          </Paper>
        </Grid>
        {selectedDiscount && (
          <Grid item xs={12}>
            <Paper
              sx={{
                p: 2,
                display: "flex",
                flexDirection: "column",
                height: "auto",
              }}
            >
              <Typography variant="h6">
                Orders using {selectedDiscount}
              </Typography>
              <br></br>
              <div style={{ height: 700, width: "100%" }}>
                <DataGrid
                  rows={filteredOrders}
                  columns={columns_orders}
                  pageSize={10}
                  rowsPerPageOptions={[10]}
                  checkboxSelection
                  onSelectionModelChange={(selection) =>
                    onOrderRowSelect(selection)
                  }
                />
              </div>
            </Paper>
          </Grid>
        )}
        {selectedOrders && (
          <Grid item xs={12}>
            <Paper
              sx={{
                p: 2,
                display: "flex",
                flexDirection: "column",
                height: "auto",
              }}
            >
              <Typography variant="h6">
                Selected Order Totals - ${selectedOrderTotal}
              </Typography>
              <br></br>
              <Button
                variant="contained"
                component="label"
                onClick={handleExportBtn}
              >
                Export Selected To Excel
              </Button>
            </Paper>
          </Grid>
        )}

        {selectedDiscount && (
          <Grid item xs={12}>
            <Paper
              sx={{
                p: 2,
                display: "flex",
                flexDirection: "column",
                height: "auto",
              }}
            >
              <Typography variant="h6">
                Customers Allowed To Use {selectedDiscount}
              </Typography>
              <br></br>
              <div style={{ height: 700, width: "100%" }}>
                <DataGrid
                  rows={filteredCustomers}
                  columns={columns_customers}
                  pageSize={10}
                  rowsPerPageOptions={[10]}
                  checkboxSelection
                />
              </div>
            </Paper>
          </Grid>
        )}
        <Grid item xs={12}>
          <Paper
            sx={{
              p: 2,
              display: "flex",
              flexDirection: "column",
              height: "auto",
            }}
          >
            <Typography variant="h6">
              Anniversary Discount Codes Manual Sync
            </Typography>
            <br></br>
            <Button
              variant="contained"
              component="label"
              onClick={ccofsSyncBtn}
            >
              CCOFS
            </Button>
          </Paper>
        </Grid>
      </Grid>
    </Await>
  );
}
