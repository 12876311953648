import {
  Alert,
  Button,
  Grid,
  Paper,
  Snackbar,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import Papa from "papaparse";
import { DataGrid } from "@mui/x-data-grid";
import { createShopifyCustomer } from "./api/ku-ordering-api/ShopifyCustomer";

import "./ShopifyCustomerCreateUpload.css";

export default function ShopifyCustomerCreateUpload() {
  const [newCustList, setNewCustList] = useState();
  const [selectedCusts, setSelectedCusts] = useState([]);
  const [failureSnackbar, setFailureSnackbar] = useState(false);
  const [successSnackbar, setSuccessSnackbar] = useState(false);

  function handleCSVUpload(event) {
    Papa.parse(event.target.files[0], {
      header: true,
      error: function (err, file, inputElem, reason) {
        console.log("Error occured parsing CSV file.");
      },
      complete: function (results) {
        // Add And ID Field And Error Field Used For Row Coloring
        setNewCustList(
          results.data.map((customer, i) => ({
            ...customer,
            id: i,
            error: null,
          }))
        );
      },
    });
  }

  async function handleCreateBtn(event) {

    let customersToCreate = [];

    // Grab All Rows If No Selection Is Made
    if (selectedCusts.length === 0) {
      customersToCreate = [...newCustList];
    } else {
      customersToCreate = selectedCusts.map((rowIndex) =>
        newCustList.at(rowIndex)
      );
    }

    // For Each Selected Customer Create A Customer Account
    const customersCreated = await Promise.all(
      customersToCreate.map(async (customer) => {
        return await createShopifyCustomer(customer);
      })
    );

    // Check If All Customers Were Created Succesfully
    if (customersCreated.every((customer) => customer != null)) {
      setSuccessSnackbar(true);
      setNewCustList(
        newCustList.map((customer) => ({ ...customer, error: false }))
      );
    } else {
      setFailureSnackbar(true);
      const newCustListUpdated = [...newCustList];
      customersCreated.map((customer, i) => {
        return (newCustListUpdated[i].error = customer === undefined);
      });
      setNewCustList(newCustListUpdated);
    }
  }

  function handleFailureSnackClose(event, reason) {
    if (reason === "clickaway") {
      return;
    }

    setFailureSnackbar(false);
  }

  function handleSuccessSnackClose(event, reason) {
    if (reason === "clickaway") {
      return;
    }

    setSuccessSnackbar(false);
  }

  const columns = [
    { field: "first_name", headerName: "First Name", width: 100 },
    { field: "last_name", headerName: "Last Lame", width: 100 },
    { field: "email", headerName: "Email", width: 200 },
    { field: "phone", headerName: "Phone Number", width: 130 },
    { field: "tags", headerName: "Customer Tags", width: 200 },
  ];

  return (
    <Grid container spacing={1}>
      <Grid item xs={12} md={12} lg={12}>
        <Paper
          sx={{
            p: 2,
            display: "flex",
            flexDirection: "column",
            height: "auto",
          }}
        >
          <Button variant="contained" component="label">
            Upload Customer CSV File
            <input
              hidden
              id="csv-upload"
              accept=".csv"
              multiple
              type="file"
              onChange={handleCSVUpload}
            />
          </Button>
        </Paper>
      </Grid>

      {newCustList && (
        <React.Fragment>
          <Grid item xs={12} md={12} lg={12}>
            <Paper
              sx={{
                p: 2,
                display: "flex",
                flexDirection: "column",
                height: "auto",
              }}
            >
              <Typography variant="h4">Customers To Be Created</Typography>
              <br></br>
              <div style={{ height: 700, width: "100%" }}>
                <DataGrid
                  rows={newCustList}
                  columns={columns}
                  pageSize={10}
                  rowsPerPageOptions={[10]}
                  checkboxSelection
                  getRowClassName={(params) => {
                    if (params.row.error == null) return "null";
                    else if (params.row.error) return "error";
                    else return "no-error";
                  }}
                  onSelectionModelChange={(selection) =>
                    setSelectedCusts(selection)
                  }
                />
              </div>
              <br></br>
              <Button
                variant="contained"
                component="label"
                onClick={handleCreateBtn}
              >
                Create Selected Customers
              </Button>
            </Paper>
          </Grid>
        </React.Fragment>
      )}
      <Snackbar
        open={failureSnackbar}
        autoHideDuration={6000}
        onClose={() => handleFailureSnackClose()}
      >
        <Alert
          onClose={() => handleFailureSnackClose()}
          severity="error"
          sx={{ width: "100%" }}
        >
          Some customers were not able to be created, they are marked in red.
        </Alert>
      </Snackbar>
      <Snackbar
        open={successSnackbar}
        autoHideDuration={6000}
        onClose={() => handleSuccessSnackClose()}
      >
        <Alert
          onClose={() => handleSuccessSnackClose()}
          severity="success"
          sx={{ width: "100%" }}
        >
          All customer were created.
        </Alert>
      </Snackbar>
    </Grid>
  );
}
