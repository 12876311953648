import React from "react";
import { DataGrid } from "@mui/x-data-grid";
import { Grid, Paper, Typography } from "@mui/material";
import { Await, Link, useLoaderData } from "react-router-dom";

export default function ShopifyOrderTable() {
  const orders = useLoaderData();

  const columns = [
    { field: "order_number", headerName: "Order Number", width: 150 },
    {
      field: "fullName",
      headerName: "Full name",
      sortable: false,
      width: 160,
      valueGetter: (params) =>
        `${params.row.customer.first_name || ""} ${
          params.row.customer.last_name || ""
        }`,
    },
    {
      field: "total_price",
      headerName: "Subtotal",
      width: 100,
      valueGetter: (params) => `$${params.row.total_price}`,
    },
    {
      field: "financial_status",
      headerName: "Paid",
      width: 100,
      valueGetter: (params) =>
        `${
          params.row.financial_status.charAt(0).toUpperCase() +
          params.row.financial_status.slice(1)
        }`,
    },
    {
      field: "fulfillment_status",
      headerName: "Fulfillment",
      width: 100,
      valueGetter: (params) => {
        if (params.row.fulfillment_status)
          return params.row.fulfillment_status.charAt(0).toUpperCase() + params.row.fulfillment_status.slice(1);
        else
          return "Not Fulfilled"
      },
    },
    {
      field: "status",
      headerName: "Status",
      width: 100,
      valueGetter: (params) => {
        return params.row.closed_at ? "Closed" : "Open";
      },
    },
    {
      field: "internal_link_1",
      headerName: "Customer",
      width: 100,
      renderCell: (params) => {
        return (
          <Link to={"/shopify-customers/" + params.row.customer.id}>Link</Link>
        );
      },
    },
    {
      field: "internal_link_2",
      headerName: "Order",
      width: 100,
      renderCell: (params) => {
        return <Link to={"/shopify-orders/" + params.id}>Link</Link>;
      },
    },
  ];

  return (
    <Await
      resolve={orders}
      // TODO - Add errorElement Here
    >
      <Grid item xs={12} md={12} lg={12}>
        <Paper
          sx={{
            p: 2,
            display: "flex",
            flexDirection: "column",
            height: "auto",
          }}
        >
          <Typography variant="h4">All Shopify Orders</Typography>
          <br></br>
          <div style={{ height: 700, width: "100%" }}>
            <DataGrid
              rows={orders}
              columns={columns}
              pageSize={10}
              rowsPerPageOptions={[10]}
              checkboxSelection
            />
          </div>
        </Paper>
      </Grid>
    </Await>
  );
}
