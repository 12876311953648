import { Grid } from "@mui/material";
import React from "react";
import ShopifyCustomerCreation from "./ShopifyCustomerCreation";
import ShopifyCustomerTable from "./ShopifyCustomerTable";

export default function ShopifyCustomerDisplay() {
  return (
    <Grid container spacing={3}>
      <ShopifyCustomerTable />
      <ShopifyCustomerCreation />
    </Grid>
  );
}
