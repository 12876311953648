import React from "react";
import { createRoot } from "react-dom/client";
import "./index.css";
import App from "./App";

import { PublicClientApplication, EventType } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";
import { msalConfig } from "./authConfig";

import { createBrowserRouter, RouterProvider } from "react-router-dom";

import ErrorPage from "./error-page";
import {
  getAllShopifyCustomers,
  getShopifyCustomerByID,
} from "./api/ku-ordering-api/ShopifyCustomer";
import ShopifyCustomerDetail from "./ShopifyCustomerDetail";
import ShopifyCustomerDisplay from "./ShopifyCustomerDisplay";
import ShopifyCustomerCreateSingle from "./ShopifyCustomerCreateSingle";
import ShopifyCustomerCreateUpload from "./ShopifyCustomerCreateUpload";
import { getAllShopifyOrders, getAllShopifyOrdersForCustomer, getShopifyOrderById } from "./api/ku-ordering-api/ShopifyOrders";
import ShopifyOrderTable from "./ShopifyOrderTable";
import ShopifyOrderDetail from "./ShopifyOrderDetail";
import ShopifyDiscountCodes from "./ShopifyDiscountCodes";
import { getAllShopifyPriceRules } from "./api/ku-ordering-api/ShopifyPriceRules";

////////////////////////////////////////
// MSAL Azure AD Authentication Items //
////////////////////////////////////////

export const msalInstance = new PublicClientApplication(msalConfig);

const accounts = msalInstance.getAllAccounts();
if (accounts.length > 0) {
  msalInstance.setActiveAccount(accounts[0]);
}

msalInstance.addEventCallback((event) => {
  if (event.eventType === EventType.LOGIN_SUCCESS && event.payload.account) {
    const account = event.payload.account;
    msalInstance.setActiveAccount(account);
  }
});

////////////////////////
// React Router Items //
////////////////////////

const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
    errorElement: <ErrorPage />,
    children: [
      {
        path: "/",
        element: <div>Home Page WIP</div>,
      },
      {
        path: "shopify-customers",
        element: <ShopifyCustomerDisplay />,
        loader: async () => {
          return getAllShopifyCustomers();
        },
      },
      {
        path: "shopify-customers/:customerID",
        element: <ShopifyCustomerDetail />,
        loader: async (req) => {
          let customer = getShopifyCustomerByID(req.params.customerID);
          let customer_orders = getAllShopifyOrdersForCustomer(req.params.customerID);
          return await(Promise.all([customer, customer_orders]));
        },
      },
      {
        path: "shopify-customers/create",
        element: <ShopifyCustomerCreateSingle />,
      },
      {
        path: "shopify-customers/create/upload-csv",
        element: <ShopifyCustomerCreateUpload />,
      },
      {
        path: "shopify-orders",
        element: <ShopifyOrderTable />,
        loader: async () => {
          return getAllShopifyOrders();
        },
      },
      {
        path: "shopify-discounts",
        element: <ShopifyDiscountCodes />,
        loader: async () => {
          let all_customers = getAllShopifyCustomers();
          let all_price_rules = getAllShopifyPriceRules();
          let all_orders = getAllShopifyOrders();
          return await(Promise.all([all_price_rules, all_orders, all_customers]));
        },
      },
      {
        path: "shopify-orders/:orderID",
        element: <ShopifyOrderDetail />,
        loader: async (req) => {
          return getShopifyOrderById(req.params.orderID);
        },
      },
    ],
  },
]);

//////////////////////////
// Primary React Render //
//////////////////////////

const container = document.getElementById("root");
const root = createRoot(container);

root.render(
  <MsalProvider instance={msalInstance}>
    <RouterProvider router={router} />
  </MsalProvider>
);
