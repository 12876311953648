export const msalConfig = {
  auth: {
    clientId: "823f697a-769d-4a0b-9dcf-832bbb24f129",
    authority:
      "https://login.microsoftonline.com/3d5d68b1-7bd9-4501-941f-af326c4c010c",
    redirectUri: window.location.origin // Allows the app to work locally and in the cloud
  },
  cache: {
    cacheLocation: "sessionStorage",
    storeAuthStateInCookie: false,
  },
};

// Add scopes here for ID token to be used at Microsoft identity platform endpoints.
export const loginRequest = {
  scopes: ["User.Read"],
};

export const apiRequest = {
  url: "https://ku-ordering-api.azurewebsites.net",
  scopes: ["api://a9602406-5868-4193-85e1-6fc43331c31d/user_impersonation"],
};
