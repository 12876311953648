import React from "react";
import { DataGrid } from "@mui/x-data-grid";
import { Grid, Paper, Typography } from "@mui/material";
import { Await, Link, useLoaderData } from "react-router-dom";

export default function ShopifyCustomerTable() {
  const customers = useLoaderData();

  const columns = [
    { field: "first_name", headerName: "First Name", width: 100 },
    { field: "last_name", headerName: "Last Lame", width: 100 },
    { field: "email", headerName: "Email", width: 200 },
    { field: "phone", headerName: "Phone Number", width: 130 },
    { field: "tags", headerName: "Customer Tags", width: 200 },
    {
      field: "internal_link",
      headerName: "Link",
      width: 100,
      renderCell: (params) => (
        <Link to={"/shopify-customers/" + params.id}>Details</Link>
      ),
    },
  ];

  return (
    <Await
      resolve={customers}
      // TODO - Add errorElement Here
    >
      <Grid item xs={12} md={12} lg={12}>
        <Paper
          sx={{
            p: 2,
            display: "flex",
            flexDirection: "column",
            height: "auto",
          }}
        >
          <Typography variant="h4">All Shopify Customers</Typography>
          <br></br>
          <div style={{ height: 700, width: "100%" }}>
            <DataGrid
              rows={customers}
              columns={columns}
              pageSize={10}
              rowsPerPageOptions={[10]}
              checkboxSelection
            />
          </div>
        </Paper>
      </Grid>
    </Await>
  );
}
