import {
  Alert,
  Box,
  Chip,
  Grid,
  Paper,
  Snackbar,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { Await, Link, useLoaderData } from "react-router-dom";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import EmailIcon from "@mui/icons-material/Email";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import Grid3x3Icon from "@mui/icons-material/Grid3x3";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import EditIcon from "@mui/icons-material/Edit";
import LabelIcon from "@mui/icons-material/Label";
import MarkEmailReadIcon from "@mui/icons-material/MarkEmailRead";
import CommentIcon from "@mui/icons-material/Comment";

import "./ShopifyCustomerDetail.css";
import { DataGrid } from "@mui/x-data-grid";
import { modifyShopifyCustomer } from "./api/ku-ordering-api/ShopifyCustomer";

export default function ShopifyCustomerDetail() {
  const [customer, customer_orders] = useLoaderData();

  const [successSnackbar, setSuccessSnackbar] = useState(false);
  const [failureSnackbar, setFailureSnackbar] = useState(false);

  const order_columns = [
    { field: "id", headerName: "Order ID", width: 150 },
    {
      field: "total_price",
      headerName: "Total Price",
      width: 100,
      valueGetter: (params) => `$${params.row.total_price}`,
    },
    {
      field: "financial_status",
      headerName: "Paid",
      width: 100,
      valueGetter: (params) =>
        `${
          params.row.financial_status.charAt(0).toUpperCase() +
          params.row.financial_status.slice(1)
        }`,
    },
    {
      field: "fulfillment_status",
      headerName: "Fulfillment",
      width: 100,
      valueGetter: (params) => {
        if (params.row.fulfillment_status)
          return (
            params.row.fulfillment_status.charAt(0).toUpperCase() +
            params.row.fulfillment_status.slice(1)
          );
        else return "Not Fulfilled";
      },
    },
    {
      field: "status",
      headerName: "Status",
      width: 100,
      valueGetter: (params) => {
        return params.row.closed_at ? "Closed" : "Open";
      },
    },
    {
      field: "internal_link_2",
      headerName: "Order",
      width: 100,
      renderCell: (params) => {
        return <Link to={"/shopify-orders/" + params.id}>Link</Link>;
      },
    },
  ];

  const essential_meta_columns = [
    {
      field: "key",
      headerName: "Name",
      width: 200,
      valueGetter: (params) => {
        let formattedKey = params.row.key
          .split("_")
          .map((frag) => frag.charAt(0).toUpperCase() + frag.slice(1))
          .join(" ");
        return formattedKey;
      },
    },
    { field: "value", headerName: "Value", width: 200, editable: true },
  ];

  const essMetaKeys = [
    "anniversary_date",
    "employer",
    "work_location",
    "job_type",
  ];

  const [essentialMetafields, setEssentialMetafields] = useState(
    essMetaKeys.map((ess_meta, i) => {
      let matched_meta = customer.meta.find(
        (cust_meta) => cust_meta.key === ess_meta
      );

      // Customer Already Has A Value For Metafield
      if (matched_meta) {
        matched_meta.meta_id = matched_meta.id;
        matched_meta.id = i;
      }

      return matched_meta
        ? matched_meta
        : { id: i, key: ess_meta, value: "Not Set" };
    })
  );

  async function handleMetaChange(row) {
    let customerPayload = { id: customer.id, metafields: [{ ...row }] };

    if (String(essentialMetafields[row.id]["value"]) === "Not Set") {
      customerPayload["metafields"][0]["key"] = essentialMetafields[row.id].key;
      delete customerPayload["metafields"][0]["id"];
    } else {
      customerPayload["metafields"][0]["id"] =
        essentialMetafields[row.id].meta_id;
    }

    essentialMetafields[row.id].value = row.value;
    let updatedCustomer = await modifyShopifyCustomer(customerPayload);

    if (updatedCustomer != null) {
      setEssentialMetafields(essentialMetafields);
      setSuccessSnackbar(true);
    } else {
      setFailureSnackbar(true);
    }
  }

  function handleSuccessSnackClose(event, reason) {
    if (reason === "clickaway") {
      return;
    }

    setSuccessSnackbar(false);
  }

  function handleFailureSnackClose(event, reason) {
    if (reason === "clickaway") {
      return;
    }

    setFailureSnackbar(false);
  }

  return (
    <Await
      resolve={customer}
      // TODO - Add errorElement Here
    >
      <Grid container spacing={3}>

        {/* Customer Info Card */}
        <Grid item xs={6} md={6} lg={6}>
          <Paper
            sx={{
              p: 2,
              display: "flex",
              flexDirection: "column",
              height: "auto",
            }}
          >
            <Typography variant="h3" align="center">
              {customer.first_name} {customer.last_name}
            </Typography>
            <Typography align="center">
              <AccountCircleIcon style={{ fontSize: 160 }} color="disabled" />
            </Typography>
            <br></br>
            <Typography
              sx={{ display: "flex", alignItems: "center", pb: "10px" }}
            >
              <EmailIcon sx={{ mr: "10px" }} />
              <a href={"mailto:".concat(customer.email)} className="email-link">
                {customer.email}
              </a>{" "}
            </Typography>
            <Typography
              sx={{ display: "flex", alignItems: "center", pb: "10px" }}
            >
              <LocalPhoneIcon sx={{ mr: "10px" }} />
              {customer.phone}
            </Typography>
          </Paper>
        </Grid>

        {/* Shopify Account Details */}
        <Grid item xs={6}>
          <Paper
            sx={{
              p: 2,
              display: "flex",
              flexDirection: "column",
              height: "auto",
            }}
          >
            <Typography variant="h4" align="center">
              Shopify Account Info
            </Typography>
            <br></br>
            <Typography
              sx={{ display: "flex", alignItems: "center", pb: "10px" }}
            >
              <Grid3x3Icon sx={{ mr: "10px" }} />
              Shopify ID: {customer.id}
            </Typography>
            <Typography
              sx={{ display: "flex", alignItems: "center", pb: "10px" }}
            >
              <CalendarMonthIcon sx={{ mr: "10px" }} />
              Created On: {new Date(customer.created_at).toLocaleString()}
            </Typography>
            <Typography
              sx={{ display: "flex", alignItems: "center", pb: "10px" }}
            >
              <EditIcon sx={{ mr: "10px" }} />
              Updated On: {new Date(customer.updated_at).toLocaleString()}
            </Typography>
            <Typography
              sx={{ display: "flex", alignItems: "center", pb: "10px" }}
            >
              <MarkEmailReadIcon sx={{ mr: "10px" }} />
              Account Status: {customer.state}
            </Typography>
            <Box sx={{ display: "flex", alignItems: "center", pb: "10px" }}>
              <LabelIcon sx={{ mr: "10px" }} />
              Tags:
              {customer.tags.split(",").map((tag) => (
                <Chip label={tag} key={tag} sx={{ ml: "5px" }} />
              ))}
            </Box>
            {customer.note && (
              <React.Fragment>
                <Typography
                  sx={{ display: "flex", alignItems: "center", pb: "10px" }}
                >
                  <CommentIcon sx={{ mr: "10px" }} />
                  Shopify Customer Notes:{" "}
                </Typography>
                <Typography sx={{ pl: "35px" }}> {customer.note} </Typography>
              </React.Fragment>
            )}
          </Paper>
        </Grid>

        {/* Shopify Essential Metafields */}
        <Grid item xs={6}>
          <Paper
            sx={{
              p: 2,
              display: "flex",
              flexDirection: "column",
              height: "auto",
            }}
          >
            <Typography variant="h4" align="center">
              Essential Metafields
            </Typography>
            <br></br>
            <div style={{ height: 400, width: "100%" }}>
              <DataGrid
                rows={essentialMetafields}
                columns={essential_meta_columns}
                onCellEditCommit={(row) => handleMetaChange(row)}
                pageSize={10}
                rowsPerPageOptions={[10]}
              />
            </div>
          </Paper>
        </Grid>

        {/* Shopify Additional Metafields */}
        <Grid item xs={6}>
          <Paper
            sx={{
              p: 2,
              display: "flex",
              flexDirection: "column",
              height: "auto",
            }}
          >
            <Typography variant="h4" align="center">
              Additional Metafields
            </Typography>
            <br></br>
            <Table sx={{ minWidth: 150 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>Name</TableCell>
                  <TableCell>Value</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {customer.meta
                  .filter((meta) => !essMetaKeys.includes(meta.key))
                  .map((meta) => {
                    return (
                      <TableRow key={meta.key}>
                        <TableCell>
                          {meta.key
                            .split("_")
                            .map(
                              (frag) =>
                                frag.charAt(0).toUpperCase() +
                                frag.slice(1) +
                                " "
                            )}
                        </TableCell>
                        <TableCell>{meta.value}</TableCell>
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
          </Paper>
        </Grid>

        {/* Order Table*/}
        <Grid item xs={12}>
          <Paper
            sx={{
              p: 2,
              display: "flex",
              flexDirection: "column",
              height: "auto",
            }}
          >
            <Typography variant="h4">Shopify Orders</Typography>
            <br></br>
            <div style={{ height: 700, width: "100%" }}>
              <DataGrid
                rows={customer_orders}
                columns={order_columns}
                pageSize={10}
                rowsPerPageOptions={[10]}
                checkboxSelection
              />
            </div>
          </Paper>
        </Grid>
        <Snackbar
          open={failureSnackbar}
          autoHideDuration={6000}
          onClose={() => handleFailureSnackClose()}
        >
          <Alert
            onClose={() => handleFailureSnackClose()}
            severity="error"
            sx={{ width: "100%" }}
          >
            Error updating metafield.
          </Alert>
        </Snackbar>
        <Snackbar
          open={successSnackbar}
          autoHideDuration={6000}
          onClose={() => handleSuccessSnackClose()}
        >
          <Alert
            onClose={() => handleSuccessSnackClose()}
            severity="success"
            sx={{ width: "100%" }}
          >
            Metafield updated successfully!
          </Alert>
        </Snackbar>
      </Grid>
    </Await>
  );
}
