import {
  Alert,
  Box,
  Button,
  Chip,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Paper,
  Select,
  Snackbar,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { createShopifyCustomer } from "./api/ku-ordering-api/ShopifyCustomer";

export default function ShopifyCustomerCreateSingle() {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [tags, setTags] = useState([]);
  const [failureSnackbar, setFailureSnackbar] = useState(false);

  const navigate = useNavigate();

  const possible_tags = ["ccofs-admin", "ccofs-clinical"];

  async function handleCreateShopifyCustomer() {
    const new_customer = {
      first_name: firstName,
      last_name: lastName,
      email: email,
      phone: phone,
      tags: tags,
    };

    const new_customer_returned = await createShopifyCustomer(new_customer);

    if (new_customer_returned) {
      navigate("/shopify-customers/".concat(new_customer_returned.customer.id));
    } else {
      setFailureSnackbar(true);
    }
  }

  function handleFailureSnackClose(event, reason) {
    if (reason === "clickaway") {
      return;
    }

    setFailureSnackbar(false);
  }

  return (
    <React.Fragment>
      <Grid item xs={12} md={12} lg={12}>
        <Paper
          sx={{
            p: 2,
            display: "flex",
            flexDirection: "column",
            height: "auto",
          }}
        >
          <Typography variant="h4">Create Shopify Customer</Typography>
          <TextField
            id="first_name"
            label="First Name"
            variant="standard"
            onChange={(event) => setFirstName(event.target.value)}
          />
          <TextField
            id="last_name"
            label="Last Name"
            variant="standard"
            onChange={(event) => setLastName(event.target.value)}
          />
          <TextField
            id="email"
            label="Email"
            variant="standard"
            onChange={(event) => setEmail(event.target.value)}
          />
          <TextField
            id="phone"
            label="Phone Number"
            variant="standard"
            onChange={(event) => setPhone(event.target.value)}
          />
          <FormControl sx={{ m: 1, width: 300 }}>
            <InputLabel id="shopify_customer_tag_label">Tags</InputLabel>
            <Select
              labelId="shopify_customer_tag_label"
              id="shopify_customer_tag"
              multiple
              value={tags}
              onChange={(event) => setTags(event.target.value)}
              input={
                <OutlinedInput id="select_customer_tag_chip" label="Tag" />
              }
              renderValue={(selected) => (
                <Box
                  sx={{
                    display: "flex",
                    flexWrap: "wrap",
                    gap: 0.5,
                  }}
                >
                  {selected.map((value) => (
                    <Chip key={value} label={value} />
                  ))}
                </Box>
              )}
            >
              {possible_tags.map((tag) => (
                <MenuItem key={tag} value={tag}>
                  {tag}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <br></br>
          <Button
            type="button"
            className="btn btn-primary"
            variant="contained"
            onClick={handleCreateShopifyCustomer}
          >
            Create Shopify Customer
          </Button>
        </Paper>
      </Grid>
      <Snackbar
        open={failureSnackbar}
        autoHideDuration={6000}
        onClose={() => handleFailureSnackClose()}
      >
        <Alert
          onClose={() => handleFailureSnackClose()}
          severity="error"
          sx={{ width: "100%" }}
        >
          Customer could not be created check if email or phone number is
          already in use.
        </Alert>
      </Snackbar>
    </React.Fragment>
  );
}
