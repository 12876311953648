import { Await, useLoaderData } from "react-router-dom";

import {
  Chip,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import EmailIcon from "@mui/icons-material/Email";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import { Box } from "@mui/system";
import React from "react";
import { DataGrid } from "@mui/x-data-grid";
import FlakyIcon from "@mui/icons-material/Flaky";
import EventIcon from "@mui/icons-material/Event";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import PriceCheckIcon from "@mui/icons-material/PriceCheck";
import PaidIcon from "@mui/icons-material/Paid";
import LocalOfferIcon from "@mui/icons-material/LocalOffer";

export default function ShopifyOrderDetail() {
  const order = useLoaderData();

  const columns = [
    { field: "title", headerName: "Product Name", width: 300 },
    { field: "price", headerName: "Price", width: 75 },
    { field: "quantity", headerName: "Quantity", width: 75 },
    { field: "variant_title", headerName: "Quantity", width: 150 },
    { field: "fulfillment_status", headerName: "Fulfilled", width: 150 },
  ];

  return (
    <Await
      resolve={order}
      // TODO - Add errorElement Here
    >
      <Grid container spacing={2}>
        {/* General Order Info */}
        <Grid item xs={6} md={6} lg={6}>
          <Paper
            sx={{
              p: 2,
              display: "flex",
              flexDirection: "column",
              height: "auto",
            }}
          >
            <Typography variant="h3">Shopify Order - {order.number}</Typography>
            <br></br>
            <Typography
              sx={{ display: "flex", alignItems: "center", pb: "10px" }}
            >
              <PaidIcon sx={{ mr: "10px" }} />
              Order Total: {order.total_price}
            </Typography>

            {order.financial_status && (
              <Typography
                sx={{ display: "flex", alignItems: "center", pb: "10px" }}
              >
                {" "}
                <PriceCheckIcon sx={{ mr: "10px" }} />
                Payment Status:{" "}
                {order.financial_status.charAt(0).toUpperCase() +
                  order.financial_status.slice(1)}
              </Typography>
            )}
            {order.fulfillment_status && (
              <Typography
                sx={{ display: "flex", alignItems: "center", pb: "10px" }}
              >
                <LocalShippingIcon sx={{ mr: "10px" }} />
                Fulfilment Status: {order.fulfillment_status}
              </Typography>
            )}
            {!order.fulfillment_status && (
              <Typography
                sx={{ display: "flex", alignItems: "center", pb: "10px" }}
              >
                <LocalShippingIcon sx={{ mr: "10px" }} />
                Fulfilment Status: Not Fulfilled
              </Typography>
            )}

            <Typography
              sx={{ display: "flex", alignItems: "center", pb: "10px" }}
            >
              <EventIcon sx={{ mr: "10px" }} />
              Created: {new Date(order.created_at).toLocaleString()}
            </Typography>
            <Typography
              sx={{ display: "flex", alignItems: "center", pb: "10px" }}
            >
              <FlakyIcon sx={{ mr: "10px" }} />
              Overall Status:{" "}
              {order.closed_at
                ? "Closed On " + new Date(order.closed_at).toLocaleString()
                : "Not Closed"}
            </Typography>
            <Box sx={{ display: "flex", alignItems: "center", pb: "10px" }}>
              <LocalOfferIcon sx={{ mr: "10px" }} />
              Tags:
              {order.tags &&
                order.tags
                  .split(",")
                  .map((tag) => (
                    <Chip label={tag} key={tag} sx={{ ml: "5px" }} />
                  ))}
            </Box>
          </Paper>
        </Grid>

        {/* Customer Info Card */}
        <Grid item xs={6} md={6} lg={6}>
          <Paper
            sx={{
              p: 2,
              display: "flex",
              flexDirection: "column",
              height: "auto",
            }}
          >
            <Typography variant="h3" align="center">
              {order.customer.first_name} {order.customer.last_name}
            </Typography>
            <Typography align="center">
              <AccountCircleIcon style={{ fontSize: 160 }} color="disabled" />
            </Typography>
            <br></br>
            <Typography
              sx={{ display: "flex", alignItems: "center", pb: "10px" }}
            >
              <EmailIcon sx={{ mr: "10px" }} />
              <a
                href={"mailto:".concat(order.customer.email)}
                className="email-link"
              >
                {order.customer.email}
              </a>{" "}
            </Typography>
            <Typography
              sx={{ display: "flex", alignItems: "center", pb: "10px" }}
            >
              <LocalPhoneIcon sx={{ mr: "10px" }} />
              {order.customer.phone}
            </Typography>
          </Paper>
        </Grid>

        {/* Shipping Card */}
        <Grid item xs={4} md={4} lg={4}>
          <Paper
            sx={{
              p: 2,
              display: "flex",
              flexDirection: "column",
              height: "auto",
            }}
          >
            <Typography variant="h6">Shipping Address</Typography>

            {order.shipping_address && (
              <Box sx={{ p: 2 }}>
                <Typography>
                  {order.shipping_address.first_name +
                    " " +
                    order.shipping_address.last_name}
                </Typography>
                <Typography>{order.shipping_address.company}</Typography>
                <Typography>{order.shipping_address.address1}</Typography>
                {order.shipping_address.address2 && (
                  <Typography>order.shipping_address.address2 </Typography>
                )}
                <Typography>
                  {order.shipping_address.city +
                    ", " +
                    order.shipping_address.province_code +
                    " " +
                    order.shipping_address.zip}
                </Typography>
                <Typography>{order.shipping_address.phone}</Typography>
              </Box>
            )}

            {!order.shipping_address && (
              <Typography>No Shipping Address Given</Typography>
            )}
          </Paper>
        </Grid>

        {/* Billing Address Card */}
        <Grid item xs={4} md={4} lg={4}>
          <Paper
            sx={{
              p: 2,
              display: "flex",
              flexDirection: "column",
              height: "auto",
            }}
          >
            <Typography variant="h6">Billing Address</Typography>

            {order.billing_address && (
              <Box sx={{ p: 2 }}>
                <Typography>
                  {order.billing_address.first_name +
                    " " +
                    order.billing_address.last_name}
                </Typography>
                <Typography>{order.billing_address.company}</Typography>
                <Typography>{order.billing_address.address1}</Typography>
                {order.billing_address.address2 && (
                  <Typography>order.billing_address.address2 </Typography>
                )}
                <Typography>
                  {order.billing_address.city +
                    ", " +
                    order.billing_address.province_code +
                    " " +
                    order.billing_address.zip}
                </Typography>
                <Typography>{order.billing_address.phone}</Typography>
              </Box>
            )}

            {!order.billing_address && (
              <Typography>No Billing Address Given</Typography>
            )}
          </Paper>
        </Grid>

        {/* Payment Info Card */}
        <Grid item xs={4} md={4} lg={4}>
          <Paper
            sx={{
              p: 2,
              display: "flex",
              flexDirection: "column",
              height: "100%",
            }}
          >
            <Typography variant="h6">Payment Details</Typography>
            {order.payment_details && (
              <React.Fragment>
                <Typography>
                  AVS Result Code: {order.payment_details.avs_result_code}
                </Typography>
                <Typography>
                  Credit Card BIN: {order.payment_details.credit_card_bin}
                </Typography>
                <Typography>
                  CVV Result Code: {order.payment_details.cvv_result_code}
                </Typography>
                <Typography>
                  Credit Card Number: {order.payment_details.credit_card_number}
                </Typography>
                <Typography>
                  Credit Card Company:{" "}
                  {order.payment_details.credit_card_company}
                </Typography>
              </React.Fragment>
            )}
            {!order.payment_details && (
              <Typography>No Payment Details</Typography>
            )}
          </Paper>
        </Grid>

        {/* Order Notes Card */}
        <Grid item xs={6} md={6} lg={6}>
          <Paper
            sx={{
              p: 2,
              display: "flex",
              flexDirection: "column",
              height: "auto",
            }}
          >
            <Typography variant="h6">Order Notes</Typography>
            {order.note && <Typography>{order.note}</Typography>}
            {!order.note && <Typography>No Order Notes</Typography>}
          </Paper>
        </Grid>

        {/* Metafields Card */}
        <Grid item xs={6} md={6} lg={6}>
          <Paper
            sx={{
              p: 2,
              display: "flex",
              flexDirection: "column",
              height: "auto",
            }}
          >
            <Typography variant="h6">Metafields</Typography>
            <Typography>To Implement</Typography>
          </Paper>
        </Grid>

        {/* Discount Card */}
        <Grid item xs={6} md={6} lg={6}>
          <Paper
            sx={{
              p: 2,
              display: "flex",
              flexDirection: "column",
              height: "auto",
            }}
          >
            <Typography variant="h6">Discounts</Typography>
            <TableContainer component={Paper}>
              <Table aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell>Code</TableCell>
                    <TableCell>Type</TableCell>
                    <TableCell>Amount</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {order.discount_codes.map((code, i) => {
                    return (
                      <TableRow key={i}>
                        <TableCell>{code.code}</TableCell>
                        <TableCell>{code.type}</TableCell>
                        <TableCell>{code.amount}</TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
        </Grid>

        {/* Refund Card */}
        <Grid item xs={6} md={6} lg={6}>
          <Paper
            sx={{
              p: 2,
              display: "flex",
              flexDirection: "column",
              height: "auto",
            }}
          >
            <Typography variant="h6">Refunds</Typography>
            <TableContainer component={Paper}>
              <Table aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell>#</TableCell>
                    <TableCell>Amount</TableCell>
                    <TableCell>Created</TableCell>
                    <TableCell>Processed</TableCell>
                    <TableCell>Notes</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {order.refunds.map((refund, i) => {
                    return (
                      <TableRow key={i}>
                        <TableCell>{i + 1}</TableCell>
                        <TableCell>
                          {"$" + refund.transactions[0].amount}
                        </TableCell>
                        <TableCell>
                          {new Date(refund.created_at).toLocaleString()}
                        </TableCell>
                        <TableCell>
                          {new Date(refund.processed_at).toLocaleString()}
                        </TableCell>
                        <TableCell>{refund.note}</TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
        </Grid>

        {/* Order Items Card */}
        <Grid item xs={12} md={12} lg={12}>
          <Paper
            sx={{
              p: 2,
              display: "flex",
              flexDirection: "column",
              height: "auto",
            }}
          >
            <Typography variant="h6">Order Items</Typography>
            <br></br>
            <div style={{ height: 700, width: "100%" }}>
              <DataGrid
                rows={order.line_items}
                columns={columns}
                pageSize={10}
                rowsPerPageOptions={[10]}
                checkboxSelection
              />
            </div>
          </Paper>
        </Grid>
      </Grid>
    </Await>
  );
}
