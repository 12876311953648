import { Grid, Typography } from "@mui/material";
import { useRouteError } from "react-router-dom";

export default function ErrorPage() {
  const error = useRouteError();
  console.error(error);

  return (
    <Grid
      container
      spacing={0}
      direction="column"
      alignItems="center"
      justifyContent="center"
      style={{ minHeight: "100vh" }}
    >
      <Grid item xs={3}>
        <Typography variant="h2">Oops!</Typography>
        <Typography variant="h4">
          Sorry, an unexpected error has occurred.
        </Typography>
        <Typography variant="h6">
          <br />
          Error:
          <br />
          <Typography fontStyle="italic" fontWeight="bold">
            {error.statusText || error.message}
          </Typography>
        </Typography>
      </Grid>
    </Grid>
  );
}
