import { Button, Grid, Paper } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";

export default function ShopifyCustomerCreation() {
  return (
    <Grid item xs={12} md={12} lg={12}>
      <Paper
        sx={{
          p: 2,
          display: "flex",
          flexDirection: "column",
          height: "auto",
        }}
      >
        <Grid container columns={2}>
          <Grid item>
            <Button
              variant="contained"
              component={Link}
              to="/shopify-customers/create"
            >
              Add Single User
            </Button>
          </Grid>
          <Grid item>
            <Button
              variant="contained"
              component={Link}
              to="/shopify-customers/create/upload-csv"
              sx={{ ml: "10px"}}
            >
              Add Multiple Users
            </Button>
          </Grid>
        </Grid>
      </Paper>
    </Grid>
  );
}
