import { apiRequest } from "../../authConfig";
import { msalInstance } from "../../index";

export async function getBearerToken() {
  const account = msalInstance.getActiveAccount();
  if (!account) {
    throw Error(
      "No active account! Verify a user has been signed in and setActiveAccount has been called."
    );
  }

  const response = await msalInstance.acquireTokenSilent({
    ...apiRequest,
    account: account,
  });

  return response.accessToken;
}


export async function getAllShopifyPriceRules() {
    const token = await getBearerToken();
  
    const headers = new Headers();
    const bearer = `Bearer ${token}`;
  
    headers.append("Authorization", bearer);
  
    const options = {
      method: "GET",
      headers: headers,
    };
  
    return fetch(
        "https://ku-ordering-api.azurewebsites.net/api/shopify_price_rule?code=1mWaznWLeF0O8FwoxGFodxYn1iiQc-1PQv9Ypf9bOoLIAzFulr8SDQ==",
      options
    )
      .then((response) => response.json())
      .catch((error) => console.log(error));
  }